import React, { useMemo } from "react";
import { ERC20__factory } from "@f3v/contracts";
import { useEthers } from "@usedapp/core";

export const useERC20 = (tokenAddress?: string) => {
  const { library, chainId } = useEthers();

  return useMemo(() => {
    if (!library) return undefined;
    if (!tokenAddress) return undefined;
    return ERC20__factory.connect(tokenAddress, library);
  }, [tokenAddress, library, chainId]);
};
