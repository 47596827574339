import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { useEthers } from "@usedapp/core";
import { useERC20 } from "./useERC20";

export const useERC20Balance = (tokenAddress?: string, owner?: string) => {
  const erc20 = useERC20(tokenAddress);
  const [balance, setBalance] = useState<BigNumber>();
  const { account, library } = useEthers();

  const fetchBalance = useCallback(async () => {
    const holder = owner || account;
    if (!erc20 || !holder) return;
    const _balance = await erc20.balanceOf(holder);
    setBalance(_balance);
  }, [erc20, account, owner]);

  useEffect(() => {
    fetchBalance();
    library?.on("block", fetchBalance);
    return () => {
      library?.off("block", fetchBalance);
    };
  }, [library, fetchBalance]);

  return balance;
};
