import React, { useCallback, useState } from "react";
import { usePrivateEquity } from "../usePrivateEquity";
import { usePrivateEquityMetaData } from "../usePrivateEquityMetadata";
import { useERC20Metadata } from "../../erc20/useERC20Metadata";
import { SafeTxState, TxState } from "../../../constants/tx";
import { useAdmin } from "./useAdmin";
import { parseUnits } from "ethers/lib/utils";
import { InvCap } from "@f3v/contracts/utils/merkle-tree";

export const useSetupAllowance = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const { sendTx } = useAdmin(fundAddress);
  const metadata = usePrivateEquityMetaData(fundAddress);
  const baseTokenMetadata = useERC20Metadata(metadata?.baseToken);
  const [txState, setTxState] = useState<TxState | SafeTxState>(
    TxState.BUILDING
  );

  const setupAllowance = useCallback(
    async (file: File) => {
      if (!privateEquity) return;
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = JSON.parse(event.target?.result as string);
        const merkleTreeData: InvCap[] = Object.keys(data).map((addr) => {
          return {
            funder: addr,
            allowance: parseUnits(
              data[addr].toString(),
              baseTokenMetadata?.decimals
            ),
          };
        });
        const tx = await privateEquity.populateTransaction.setupAllowance(
          merkleTreeData
        );
        const response = await sendTx(tx);
        if (response?.transaction) {
          setTxState(TxState.PENDING);
          response.transaction
            .wait()
            .then(() => setTxState(TxState.CONFIRMED))
            .catch(() => setTxState(TxState.FAILED));
        } else if (response?.safeTransaction) {
          setTxState(SafeTxState.CREATED);
        }
      };
      reader.readAsText(file);
    },
    [privateEquity, sendTx, baseTokenMetadata]
  );

  return { txState, setupAllowance };
};
