import React from "react";
import { Box, Button, Heading } from "grommet";
import { SafeTxState, TxState } from "../../constants/tx";
import { useCloseFundRaising } from "../../hooks/private-equity/admin/useCloseFundRaising";

export function CloseFundRaising({ fundAddress }: { fundAddress: string }) {
  const { txState, closeFundRaising } = useCloseFundRaising(fundAddress);
  return (
    <Box>
      <Heading level={3}>Close Fund</Heading>
      <Button
        fill="horizontal"
        disabled={
          txState === TxState.PENDING || txState === SafeTxState.CREATED
        }
        label={
          txState === TxState.PENDING
            ? "closeFundRaising tx is confirmed..."
            : txState === SafeTxState.CREATED
            ? "Created a multisig transaction"
            : "Close fund"
        }
        onClick={async () => {
          const ok = window.confirm("Will you close the fund raising?");
          if (ok) {
            closeFundRaising();
          }
        }}
      />
    </Box>
  );
}
