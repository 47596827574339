import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";

export const useReleasedToken = (fundAddress: string, tokenAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [releasedToken, setReleasedToken] = useState<BigNumber>();
  const { library, chainId, account } = useEthers();

  const fetchReleasedToken = useCallback(async () => {
    if (!privateEquity || !account) return;
    const _releasedToken = await privateEquity["released(address,address)"](
      tokenAddress,
      account
    );
    setReleasedToken(_releasedToken);
  }, [library, chainId, fundAddress, account]);

  useEffect(() => {
    fetchReleasedToken();
    library?.on("block", fetchReleasedToken);
    return () => {
      library?.off("block", fetchReleasedToken);
    };
  }, [library, chainId, fundAddress, account]);

  return releasedToken;
};
