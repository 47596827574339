import React from "react";
import { Box, Heading, Text } from "grommet";
import { useERC20Balance } from "../hooks/erc20/useERC20Balance";
import { USDC } from "../constants/tokens";
import { useETHBalance } from "../hooks/erc20/useETHBalance";
import { useERC20Metadata } from "../hooks/erc20/useERC20Metadata";
import { useEthers } from "@usedapp/core";
import { formatCurrency } from "../utils/utils";

export default function MyPage() {
  const { chainId } = useEthers();
  const usdcBalance = useERC20Balance(USDC(chainId) || "");
  const metadata = useERC20Metadata(USDC(chainId) || "");
  const ethBalance = useETHBalance();
  return (
    <Box flex align="center" justify="center">
      <Heading level={3}> My balance</Heading>
      <Text size={"large"}>Ether: {formatCurrency(ethBalance, 18, "ETH")}</Text>
      <Text size={"large"}>
        USDC:{" "}
        {formatCurrency(usdcBalance, metadata?.decimals, metadata?.symbol)}
      </Text>
    </Box>
  );
}
