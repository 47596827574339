import React, { useCallback, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";
import { TxState } from "../../constants/tx";
import { useMerkleProof } from "./useMerkleProof";
import { SignatureData } from "../erc20/usePermitSignature";
import { useAllowanceWithDecryption } from "./useAllowanceWithDecryption";

export const useFundWithPermit = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [txState, setTxState] = useState<TxState>(TxState.BUILDING);
  const { library, account } = useEthers();
  const { proof } = useMerkleProof(fundAddress);
  const { allowance } = useAllowanceWithDecryption(fundAddress);

  const fundWithPermit = useCallback(
    async (amount: BigNumber, signature: SignatureData) => {
      if (!privateEquity || !account || !allowance || !proof) return;
      if (!library) return;
      const signer = library.getSigner(account);
      privateEquity
        .connect(signer)
        .fundWithPermit(
          account,
          amount,
          allowance,
          proof,
          signature.deadline,
          signature.v,
          signature.r,
          signature.s
        )
        .then((tx) => {
          setTxState(TxState.PENDING);
          tx.wait()
            .then(() => setTxState(TxState.CONFIRMED))
            .catch(() => setTxState(TxState.FAILED));
        })
        .catch(() => setTxState(TxState.CANCELLED));
    },
    [privateEquity, account, allowance, proof, library]
  );
  return { txState, fundWithPermit };
};
