import React, { useCallback, useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import { useERC20 } from "./useERC20";

interface ERC20Metadata {
  name: string;
  decimals: number;
  symbol: string;
}
const erc20MetadataMemory: { [token: string]: ERC20Metadata } = {};

export const useERC20Metadata = (tokenAddress?: string) => {
  const { library, chainId } = useEthers();
  const erc20 = useERC20(tokenAddress);
  const getCachedData = () => {
    if (!tokenAddress) return undefined;
    const hasMemory = Object.prototype.hasOwnProperty.call(
      erc20MetadataMemory,
      tokenAddress
    );
    if (hasMemory) {
      return erc20MetadataMemory[tokenAddress];
    } else {
      return undefined;
    }
  };
  const [metadata, setMetadata] = useState<ERC20Metadata | undefined>(
    getCachedData
  );

  const fetchTokenMetadata = useCallback(async () => {
    if (!erc20) return;
    if (metadata) return;
    if (!tokenAddress) return;
    const [_name, _decimals, _symbol] = await Promise.all([
      erc20.name(),
      erc20.decimals(),
      erc20.symbol(),
    ]);
    const _metadata = {
      name: _name,
      decimals: _decimals,
      symbol: _symbol,
    };
    erc20MetadataMemory[tokenAddress] = _metadata;
    setMetadata(_metadata);
  }, [tokenAddress, library, chainId]);

  useEffect(() => {
    fetchTokenMetadata();
  }, [tokenAddress, library, chainId]);
  return metadata;
};
