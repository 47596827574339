import React, { Component } from "react";
import { Anchor, Box, Heading, Text } from "grommet";

export default function Welcome() {
  return (
    <Box flex align="center" justify="center">
      <Heading level={3}> Welcome to F3V asset management program!</Heading>
    </Box>
  );
}
