export const fundList: {
  [chainId: number]: { name: string; address: string }[];
} = {
  1: [
    {
      name: "F3V Fund 1",
      address: "0xA01780c2b9eD558fDD5825DfFb377CB4f24a1Af9",
    },
  ],
  4: [
    {
      name: "F3V Fund 1",
      address: "0x20a5a96D6938752cf0e2a44B580Dd6E6b83Bea98",
    },
  ],
  5: [
    {
      name: "F3V Fund 1",
      address: "0x20a5a96D6938752cf0e2a44B580Dd6E6b83Bea98",
    },
  ],
};
