import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";

export const useShares = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [shares, setShares] = useState<BigNumber>();
  const { account, library, chainId } = useEthers();

  const fetchShares = useCallback(async () => {
    if (!privateEquity || !account) return;
    const _totalShares = await privateEquity.shares(account);
    setShares(_totalShares);
  }, [library, chainId, fundAddress, account]);

  useEffect(() => {
    fetchShares();
    library?.on("block", fetchShares);
    return () => {
      library?.off("block", fetchShares);
    };
  }, [library, chainId, fundAddress, account]);

  return shares;
};
