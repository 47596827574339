"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FundFactory__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        name: "newFundWithTicketInfo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
const _bytecode = "0x608060405234801561001057600080fd5b5061027c806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063d0cc578914610030575b600080fd5b61004a600480360381019061004591906100fa565b61004c565b005b50565b600061006261005d84610160565b61013b565b9050808382526020820190508285602086028201111561008157600080fd5b60005b858110156100b1578161009788826100bb565b845260208401935060208301925050600181019050610084565b5050509392505050565b6000813590506100ca8161022f565b92915050565b600082601f8301126100e157600080fd5b81356100f184826020860161004f565b91505092915050565b60006020828403121561010c57600080fd5b600082013567ffffffffffffffff81111561012657600080fd5b610132848285016100d0565b91505092915050565b6000610145610156565b905061015182826101be565b919050565b6000604051905090565b600067ffffffffffffffff82111561017b5761017a6101ef565b5b602082029050602081019050919050565b60006101978261019e565b9050919050565b600073ffffffffffffffffffffffffffffffffffffffff82169050919050565b6101c78261021e565b810181811067ffffffffffffffff821117156101e6576101e56101ef565b5b80604052505050565b7f4e487b7100000000000000000000000000000000000000000000000000000000600052604160045260246000fd5b6000601f19601f8301169050919050565b6102388161018c565b811461024357600080fd5b5056fea26469706673582212203c22b851b989be2e413f53cb80267ff89b180fdcb34fa9b70d6063fa4479c29464736f6c63430008040033";
class FundFactory__factory extends ethers_1.ContractFactory {
    constructor(...args) {
        if (args.length === 1) {
            super(_abi, _bytecode, args[0]);
        }
        else {
            super(...args);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.FundFactory__factory = FundFactory__factory;
FundFactory__factory.bytecode = _bytecode;
FundFactory__factory.abi = _abi;
