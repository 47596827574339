import React, { useMemo } from "react";
import { PrivateEquity__factory } from "@f3v/contracts";
import { useEthers } from "@usedapp/core";

export const usePrivateEquity = (fundAddress: string) => {
  const { account, library, chainId } = useEthers();
  const signer = account ? library?.getSigner(account) : undefined;
  return useMemo(() => {
    if (!library) return undefined;
    const _contract = PrivateEquity__factory.connect(fundAddress, library);
    return signer ? _contract.connect(signer) : _contract;
  }, [library, fundAddress, signer, chainId]);
};
