import React, { useState } from "react";
import { Box, Button, Heading, FileInput } from "grommet";
import { SafeTxState as SafeTxState, TxState } from "../../constants/tx";
import { useSetupAllowance } from "../../hooks/private-equity/admin/useSetupAllowance";

export function SetUpAllowance({ fundAddress }: { fundAddress: string }) {
  const [file, setFile] = useState<File>();

  const { txState, setupAllowance } = useSetupAllowance(fundAddress);
  return (
    <Box>
      <Heading level={3}>Setup Allowance</Heading>
      <FileInput
        name="file"
        multiple={false}
        onChange={(event) => {
          const fileList = event.target.files;
          setFile(fileList ? fileList[0] : undefined);
        }}
      />
      <hr />
      <Button
        fill="horizontal"
        disabled={
          txState === TxState.PENDING || txState === SafeTxState.CREATED
        }
        label={
          txState === TxState.PENDING
            ? "'Set up allowance' transaction is confirmed..."
            : txState === SafeTxState.CREATED
            ? "Created a multisig transaction"
            : "Update"
        }
        onClick={() => {
          if (!file) alert("Please select a json file");
          else setupAllowance(file);
        }}
      />
    </Box>
  );
}
