import React, { useCallback, useState } from "react";
import { BigNumber } from "ethers";
import Safe from "@gnosis.pm/safe-core-sdk";
import { usePrivateEquity } from "../usePrivateEquity";
import { SafeTxState, TxState } from "../../../constants/tx";
import { useAdmin } from "./useAdmin";

export const useCloseFundRaising = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const { sendTx } = useAdmin(fundAddress);
  const [txState, setTxState] = useState<TxState | SafeTxState>(
    TxState.BUILDING
  );

  const closeFundRaising = useCallback(async () => {
    if (!privateEquity) return;
    const tx = await privateEquity.populateTransaction.closeFundRaising();
    const response = await sendTx(tx);
    console.log("response", response);
    if (response?.transaction) {
      setTxState(TxState.PENDING);
      response.transaction
        .wait()
        .then(() => setTxState(TxState.CONFIRMED))
        .catch(() => setTxState(TxState.FAILED));
    } else if (response?.safeTransaction) {
      setTxState(SafeTxState.CREATED);
    }
  }, [privateEquity, sendTx]);

  return { txState, closeFundRaising };
};
