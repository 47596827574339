import React from "react";
import { useParams } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { Anchor, Box, Heading, Text } from "grommet";
import { getAddress } from "ethers/lib/utils";
import { formatCurrency } from "../utils/utils";
import { fundList } from "../constants/fund";
import { useTotalShares } from "../hooks/private-equity/useTotalShares";
import { usePrivateEquityMetaData } from "../hooks/private-equity/usePrivateEquityMetadata";
import { useERC20Metadata } from "../hooks/erc20/useERC20Metadata";
import { useERC20Balance } from "../hooks/erc20/useERC20Balance";
import Safe from "@gnosis.pm/safe-core-sdk";
import { useAdmin } from "../hooks/private-equity/admin/useAdmin";
import { SetUpAllowance } from "../components/admin/SetUpAllowance";
import { CloseFundRaising } from "../components/admin/CloseFundRaising";
import { Transfer } from "../components/admin/Transfer";

function AdminBox({ fund }: { fund: { name: string; address: string } }) {
  const totalShares = useTotalShares(fund.address);
  const metadata = usePrivateEquityMetaData(fund.address);
  const { admin, address } = useAdmin(fund.address);
  const baseTokenMetadata = useERC20Metadata(metadata?.baseToken);
  const usdcBalance = useERC20Balance(metadata?.baseToken, fund.address);

  const notAvailable = (
    <Box flex align="start" justify="center">
      Not available
    </Box>
  );

  const checkingAdminPermission = (
    <Box flex align="start" justify="center">
      Checking the admin permission.
    </Box>
  );

  const forAdminOnly = () => {
    if (admin === undefined) throw Error("Admin should not be undefined");
    const adminInfo =
      admin instanceof Safe
        ? `https://gnosis-safe.io/app/${address}`
        : `https://etherscan.io/address/${address}`;

    return (
      <Box flex align="start" justify="center">
        <Heading level={3}>Admin info</Heading>
        <Text size={"large"}>
          <Anchor href={adminInfo} target={"_blank"}>
            {admin instanceof Safe
              ? "Go to gnosis safe wallet"
              : "Go to etherscan"}
          </Anchor>
        </Text>
        <Heading level={3}>{fund.name}</Heading>
        <Text size={"large"}>
          State:{" "}
          {metadata ? (metadata.closed ? "closed" : "opened") : "fetching..."}
        </Text>
        <Text size={"large"}>
          Raised:{" "}
          {formatCurrency(totalShares, baseTokenMetadata?.decimals || 6, "")}
        </Text>
        <Text size={"large"}>
          {baseTokenMetadata?.symbol} balance:{" "}
          {formatCurrency(usdcBalance, baseTokenMetadata?.decimals || 6, "")}
        </Text>
        {!metadata?.closed && (
          <>
            <SetUpAllowance fundAddress={fund.address} />
            <CloseFundRaising fundAddress={fund.address} />
          </>
        )}
        <Transfer fundAddress={fund.address} />
      </Box>
    );
  };
  return admin === null
    ? notAvailable
    : admin === undefined
    ? checkingAdminPermission
    : forAdminOnly();
}

export default function FundAdmin() {
  const { chainId } = useEthers();
  const funds = chainId ? fundList[chainId] : [];
  let { address } = useParams<"address">();
  const fund = funds.find(
    (f) => !!address && f.address === getAddress(address)
  );
  if (!fund) {
    return (
      <Box flex align="center" justify="center">
        <Heading level={3}>Not a valid fund address</Heading>
        <Text size={"large"}>
          <Anchor
            href={`https://etherscan.io/address/${address}`}
            target={"_blank"}
          >
            {address}
          </Anchor>
        </Text>
      </Box>
    );
  }

  return <AdminBox fund={fund} />;
}
