import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChainId, DAppProvider, Config } from "@usedapp/core";

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]:
      process.env.WEB3_API ||
      "https://eth-mainnet.alchemyapi.io/v2/2Ll6NW_xQtnGlVoNRIvxzdU53ZwDc9Jg",
  },
};
ReactDOM.render(
  <DAppProvider config={config}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </DAppProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
