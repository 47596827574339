import React, { useState } from "react";
import {
  Box,
  Button,
  BoxExtendedProps,
  Grommet,
  Collapsible,
  ResponsiveContext,
  Nav,
  Sidebar,
  Heading,
  Anchor,
} from "grommet";
import { Menu, User, Search, Home as HomeIcon } from "grommet-icons";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import MyPage from "./pages/MyPage";
import FundDetails from "./pages/FundDetails";
import FundAdmin from "./pages/FundAdmin";
import { useEthers } from "@usedapp/core";
import Connect from "./pages/Connect";
import Explore from "./pages/Explore";
import WalletConnectProvider from "@walletconnect/web3-provider";

const theme = {
  global: {
    colors: {
      brand: "#015450",
    },
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
  },
};

const AppBar = (
  props: JSX.IntrinsicAttributes &
    BoxExtendedProps & { children?: React.ReactNode }
) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="brand"
    pad={{ left: "medium", right: "small", vertical: "small" }}
    elevation="medium"
    style={{ zIndex: "1", fontWeight: 100 }}
    {...props}
  />
);

const MainContents = () => {
  const { account } = useEthers();
  return !account ? (
    <Connect />
  ) : (
    <Box flex align="center" justify="center">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/fund/:address" element={<FundDetails />} />
        <Route path="/fund/:address/admin" element={<FundAdmin />} />
        <Route path="/explore" element={<Explore />} />
      </Routes>
    </Box>
  );
};

function App() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Grommet theme={theme} full>
      <ResponsiveContext.Consumer>
        {(size) =>
          size === "small" ? (
            <Box fill>
              <AppBar>
                F3V Fund Manager App
                <Button
                  icon={<Menu />}
                  hoverIndicator
                  onClick={() => {
                    setShowMenu(!showMenu);
                  }}
                />
              </AppBar>
              <Box direction="row" flex overflow={{ horizontal: "hidden" }}>
                <Collapsible direction="horizontal" open={showMenu}>
                  <Box
                    flex
                    width="medium"
                    background="light-2"
                    elevation="small"
                    align="center"
                    justify="center"
                  >
                    {[
                      ["Home", "/"],
                      ["My page", "/my-page"],
                      ["Explore", "/explore"],
                    ].map(([label, path]) => (
                      <Box pad={"medium"}>
                        <Anchor
                          size={"xxlarge"}
                          label={label}
                          onClick={() => navigate(path)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Collapsible>
                {!showMenu && <MainContents />}
              </Box>
            </Box>
          ) : (
            <Box fill>
              <Box direction="row" flex overflow={{ horizontal: "hidden" }}>
                <Sidebar
                  background="brand"
                  align="center"
                  header={
                    <Heading level={3} style={{ fontWeight: 100, margin: 10 }}>
                      F3V
                    </Heading>
                  }
                >
                  <Nav gap="small">
                    <Button
                      icon={<HomeIcon />}
                      onClick={() => navigate("/", { replace: true })}
                    />
                    <Button
                      icon={<User />}
                      onClick={() => navigate("/mypage", { replace: true })}
                    />
                    <Button
                      icon={<Search />}
                      onClick={() => navigate("/explore", { replace: true })}
                    />
                  </Nav>
                </Sidebar>
                <MainContents />
              </Box>
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
