import React from "react";
import { Box, Button } from "grommet";
import { useNavigate } from "react-router-dom";

export default function FundPreview({
  name,
  fundAddress,
}: {
  name: string;
  fundAddress: string;
}) {
  const navigate = useNavigate();
  return (
    <Box justify="center" align="center" height="100px" width="100px">
      <Button
        fill
        primary
        label={name}
        onClick={() => navigate(`/fund/${fundAddress}`)}
      />
    </Box>
  );
}
