import React, { useMemo } from "react";
import { utils } from "@f3v/contracts";
import { useEthers } from "@usedapp/core";
import { useAllowanceWithDecryption } from "./useAllowanceWithDecryption";

export const useMerkleProof = (fundAddress: string) => {
  const { account } = useEthers();
  const { allowance, merkleTreeData } = useAllowanceWithDecryption(fundAddress);
  return useMemo(() => {
    if (!account || !merkleTreeData || !allowance) return { proof: undefined };
    const proof = utils.proof(merkleTreeData, account);
    return { proof };
  }, [account, allowance, merkleTreeData]);
};
