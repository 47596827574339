import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";

export const useTotalShares = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [totalShares, setTotalShares] = useState<BigNumber>();
  const { library, chainId } = useEthers();

  const fetchTotalShares = useCallback(async () => {
    if (!privateEquity) return;
    const _totalShares = await privateEquity.totalShares();
    setTotalShares(_totalShares);
  }, [fundAddress, library, chainId]);

  useEffect(() => {
    fetchTotalShares();
    library?.on("block", fetchTotalShares);
    return () => {
      library?.off("block", fetchTotalShares);
    };
  }, [fundAddress, library, chainId]);

  return totalShares;
};
