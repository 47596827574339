import { ChainId } from "@usedapp/core";

export const USDC_RINKEBY = "0xeb8f08a975Ab53E34D8a0330E0D34de942C95926";
export const USDC_MAINNET = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const USDC_GOERLI = "0x07865c6E87B9F70255377e024ace6630C1Eaa37F";

export const USDC = (chainId?: ChainId) => {
  if (!chainId) return undefined;
  if (chainId === 1) return USDC_MAINNET;
  if (chainId === 4) return USDC_RINKEBY;
  if (chainId === 5) return USDC_GOERLI;
  else return USDC_MAINNET;
};
