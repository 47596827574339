import React, { useCallback, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "../usePrivateEquity";
import { SafeTxState, TxState } from "../../../constants/tx";
import { useAdmin } from "./useAdmin";

export const useTransfer = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const { sendTx } = useAdmin(fundAddress);
  const [txState, setTxState] = useState<TxState | SafeTxState>(
    TxState.BUILDING
  );

  const transfer = useCallback(
    async (to: string, amount: BigNumber) => {
      if (!privateEquity) return;
      const tx = await privateEquity.populateTransaction.transfer(to, amount);
      const response = await sendTx(tx);
      console.log("response", response);
      if (response?.transaction) {
        setTxState(TxState.PENDING);
        response.transaction
          .wait()
          .then(() => setTxState(TxState.CONFIRMED))
          .catch(() => setTxState(TxState.FAILED));
      } else if (response?.safeTransaction) {
        setTxState(SafeTxState.CREATED);
      }
    },
    [privateEquity, sendTx]
  );

  return { txState, transfer };
};
