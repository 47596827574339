import React from "react";
import { Box, Button, Heading, Image } from "grommet";
import { useEthers } from "@usedapp/core";
import { useWalletConnect } from "../hooks/provider/useWalletConnect";

export default function Connect() {
  const { activateBrowserWallet } = useEthers();
  const { activateWalletConnect } = useWalletConnect();
  return (
    <Box flex align="center" justify="center">
      <Heading level={3}>Please connect your wallet</Heading>
      <Box
        direction="row"
        justify="center"
        align="center"
        pad="medium"
        gap="medium"
      >
        <Box justify="center" align="center" height="100px" width="100px">
          <Button
            fill
            onClick={() => activateBrowserWallet()}
            primary
            icon={
              <Image fill src={process.env.PUBLIC_URL + "/images/mm.svg"} />
            }
          />
        </Box>
        <Box justify="center" align="center" height="100px" width="100px">
          <Button
            fill
            onClick={() => activateWalletConnect()}
            primary
            icon={
              <Image
                fill
                src={process.env.PUBLIC_URL + "/images/walletconnect.svg"}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
