import React, { useCallback, useEffect, useState, useMemo } from "react";
import { BigNumber } from "ethers";
import { ERC20Permit__factory } from "@f3v/contracts";
import { useEthers } from "@usedapp/core";

export const useERC20Permit = (tokenAddress?: string) => {
  const { account, library, chainId } = useEthers();

  const erc20Permit = useMemo(() => {
    if (!library) return undefined;
    if (!tokenAddress) return undefined;
    return ERC20Permit__factory.connect(tokenAddress, library);
  }, [tokenAddress, library, chainId]);
  const [nonce, setNonce] = useState<BigNumber>();

  const fetchNonces = useCallback(async () => {
    if (!erc20Permit || !account) return;
    const _nonce = await erc20Permit.nonces(account);
    setNonce(_nonce);
  }, [library, chainId, tokenAddress, account]);

  useEffect(() => {
    fetchNonces();
    library?.on("block", fetchNonces);
    return () => {
      library?.off("block", fetchNonces);
    };
  }, [library, chainId, tokenAddress, account]);
  return {
    nonce,
    erc20Permit,
  };
};
