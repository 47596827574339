export enum TxState {
  BUILDING,
  PENDING,
  CONFIRMED,
  CANCELLED,
  FAILED,
}

export enum SafeTxState {
  CREATED = "MULTISIG_CREATED",
  CANCELLED = "MULTISIG_CANCELLED",
  CONFIRMED = "MULTISIG_CONFIRMED",
}
