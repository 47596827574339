import WalletConnectProvider from "@walletconnect/web3-provider";
import { BigNumberish } from "ethers";
import { formatUnits } from "ethers/lib/utils";

export const formatCurrency = (
  val?: BigNumberish,
  decimals?: number,
  symbol?: string,
  precision?: number
) =>
  `${parseFloat(formatUnits(val || 0, decimals || 18)).toPrecision(
    precision || 7
  )} ${symbol}`;

export const isWalletConnect = (library: any) => {
  return library
    ? (library as any).provider instanceof WalletConnectProvider
    : false;
};
