import React, { useMemo } from "react";
import { useEthers } from "@usedapp/core";
import { InvCap } from "@f3v/contracts/utils/merkle-tree";
import { utils } from "@f3v/contracts";
import { useMerkleTreeData } from "./useMerkleTreeData";
import { useAllowanceRoot } from "./useAllowanceRoot";
import { usePrivateEquityMetaData } from "./usePrivateEquityMetadata";
import { useERC20Metadata } from "../erc20/useERC20Metadata";
import { parseUnits } from "ethers/lib/utils";

export const useAllowanceWithDecryption = (fundAddress: string) => {
  const { account } = useEthers();
  const privateEquityMetadata = usePrivateEquityMetaData(fundAddress);
  const baseTokenMetadata = useERC20Metadata(privateEquityMetadata?.baseToken);
  const allowanceRoot = useAllowanceRoot(fundAddress);
  const { merkleTreeData: data, decryptData } = useMerkleTreeData(fundAddress);

  return useMemo(() => {
    if (!!data && !!account && baseTokenMetadata) {
      const merkleTreeData: InvCap[] = Object.keys(data).map((address) => ({
        funder: address,
        allowance: parseUnits(`${data[address]}`, baseTokenMetadata.decimals),
      }));
      const _root = utils.root(merkleTreeData);
      const allowance = data[account]
        ? parseUnits(`${data[account]}`, baseTokenMetadata.decimals)
        : undefined;
      if (allowanceRoot === _root) {
        return { allowance, merkleTreeData, decryptData };
      }
    }
    return { allowance: undefined, merkleTreeData: undefined, decryptData };
  }, [account, allowanceRoot, baseTokenMetadata, data, decryptData]);
};
