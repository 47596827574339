import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";

export const useTotalReleasedToken = (
  fundAddress: string,
  tokenAddress: string
) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [totalReleasedToken, setTotalReleasedToken] = useState<BigNumber>();
  const { library, chainId } = useEthers();

  const fetchTotalReleasedToken = useCallback(async () => {
    if (!privateEquity) return;
    const _totalReleasedToken = await privateEquity["totalReleased(address)"](
      tokenAddress
    );
    setTotalReleasedToken(_totalReleasedToken);
  }, [fundAddress, library, chainId]);

  useEffect(() => {
    fetchTotalReleasedToken();
    library?.on("block", fetchTotalReleasedToken);
    return () => {
      library?.off("block", fetchTotalReleasedToken);
    };
  }, [fundAddress, library, chainId]);

  return totalReleasedToken;
};
