import React, { useCallback, useState } from "react";
import { BigNumber } from "ethers";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";
import { TxState } from "../../constants/tx";

export const useWithdraw = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [txState, setTxState] = useState<TxState>(TxState.BUILDING);
  const { library, account } = useEthers();

  const withdraw = useCallback(
    async (amount: BigNumber) => {
      if (!privateEquity || !account) return;
      if (!library) return;
      const signer = library.getSigner(account);
      privateEquity
        .connect(signer)
        .withdraw(amount)
        .then((tx) => {
          setTxState(TxState.PENDING);
          tx.wait()
            .then(() => setTxState(TxState.CONFIRMED))
            .catch(() => setTxState(TxState.FAILED));
        })
        .catch(() => setTxState(TxState.CANCELLED));
    },
    [privateEquity, account, library]
  );
  return { txState, withdraw };
};
