"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FUND = exports.USDC = exports.USDC_GOERLI = exports.USDC_RINKEBY = exports.USDC_MAINNET = exports.FUND_GOERLI = exports.FUND_RINKEBY = exports.FUND_MAINNET = void 0;
exports.FUND_MAINNET = "0xA01780c2b9eD558fDD5825DfFb377CB4f24a1Af9";
exports.FUND_RINKEBY = "0x20a5a96D6938752cf0e2a44B580Dd6E6b83Bea98";
exports.FUND_GOERLI = "0x20a5a96D6938752cf0e2a44B580Dd6E6b83Bea98";
exports.USDC_MAINNET = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
exports.USDC_RINKEBY = "0xeb8f08a975Ab53E34D8a0330E0D34de942C95926";
exports.USDC_GOERLI = "0x07865c6E87B9F70255377e024ace6630C1Eaa37F";
exports.USDC = {
    1: exports.USDC_MAINNET,
    4: exports.USDC_RINKEBY,
    5: exports.USDC_GOERLI,
};
exports.FUND = {
    1: exports.FUND_MAINNET,
    4: exports.FUND_RINKEBY,
    5: exports.FUND_GOERLI,
};
