import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Money } from "grommet-icons";
import { useEthers } from "@usedapp/core";
import {
  Anchor,
  Box,
  Button,
  Heading,
  RangeInput,
  Form,
  Text,
  TextInput,
} from "grommet";
import { BigNumber } from "ethers";
import { getAddress, parseUnits, formatUnits } from "ethers/lib/utils";
import { formatCurrency } from "../utils/utils";
import { fundList } from "../constants/fund";
import { useTotalShares } from "../hooks/private-equity/useTotalShares";
import { useShares } from "../hooks/private-equity/useShares";
import { usePrivateEquityMetaData } from "../hooks/private-equity/usePrivateEquityMetadata";
import { useERC20Metadata } from "../hooks/erc20/useERC20Metadata";
import { useERC20Balance } from "../hooks/erc20/useERC20Balance";
import { useFundWithPermit } from "../hooks/private-equity/useFundWithPermit";
import {
  SignatureData,
  usePermitSignature,
} from "../hooks/erc20/usePermitSignature";
import { TxState } from "../constants/tx";
import { useWithdraw } from "../hooks/private-equity/useWithdraw";
import { useAdmin } from "../hooks/private-equity/admin/useAdmin";
import { useAllowanceWithDecryption } from "../hooks/private-equity/useAllowanceWithDecryption";
import TokenRelease from "../components/TokenRelease";
import { useWalletConnect } from "../hooks/provider/useWalletConnect";

const hardCodedTokens: string[] = [
  // "0x70cBa46d2e933030E2f274AE58c951C800548AeF", // GOERLI BAT
]; // todo
const testHardCodedTokens: string[] = [
  "0x70cBa46d2e933030E2f274AE58c951C800548AeF", // GOERLI BAT
]; // todo

function Details({ fund }: { fund: { name: string; address: string } }) {
  const { library, chainId } = useEthers();
  const totalShares = useTotalShares(fund.address);
  const shares = useShares(fund.address);
  const { allowance, decryptData } = useAllowanceWithDecryption(fund.address);
  const { admin } = useAdmin(fund.address);
  const { wcMessage } = useWalletConnect();
  const metadata = usePrivateEquityMetaData(fund.address);
  const baseTokenMetadata = useERC20Metadata(metadata?.baseToken);
  const remaining = allowance?.sub(shares || 0) || BigNumber.from(0);
  const balance = useERC20Balance(metadata?.baseToken);
  const [amountToDeposit, setAmountToDeposit] = useState<BigNumber>();
  const [amountToWithdraw, setAmountToWithdraw] = useState<BigNumber>();
  const { txState: fundTxState, fundWithPermit } = useFundWithPermit(
    fund.address
  );
  const { txState: withdrawTxState, withdraw } = useWithdraw(fund.address);
  const { signPermitData } = usePermitSignature(
    fund.address,
    metadata?.baseToken
  );
  const navigate = useNavigate();

  const [signature, setSignature] = useState<SignatureData>();
  const [password, setPassword] = useState<string>("");
  const tokens = chainId === 1 ? hardCodedTokens : testHardCodedTokens;

  const needPassword = () => (
    <Box flex align="start" justify="center">
      <Heading level={3}>{fund.name}</Heading>
      <Form
        onSubmit={() => {
          if (!!password) {
            decryptData(password).catch((_) => {
              window.alert("Invalid password");
            });
          }
        }}
      >
        <TextInput
          placeholder={`Type password`}
          type={"password"}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
      </Form>
      <br />
    </Box>
  );

  const details = () => (
    <Box flex align="start" justify="center">
      <Heading level={3}>{fund.name}</Heading>
      <Text size={"large"}>
        State:{" "}
        {metadata ? (metadata.closed ? "closed" : "opened") : "fetching..."}
      </Text>
      <Text size={"large"}>
        Total shares:{" "}
        {formatCurrency(
          totalShares,
          baseTokenMetadata?.decimals || 6,
          "Shares",
          8
        )}
      </Text>
      <Text size={"large"}>
        My shares:{" "}
        {formatCurrency(shares, baseTokenMetadata?.decimals || 6, "Shares", 6)}
      </Text>
      <Text size={"large"}>
        Allowance:{" "}
        {formatCurrency(
          allowance,
          baseTokenMetadata?.decimals || 6,
          baseTokenMetadata?.symbol
        )}
      </Text>
      <Text size={"large"}>
        Remaining:{" "}
        {formatCurrency(
          remaining,
          baseTokenMetadata?.decimals || 6,
          baseTokenMetadata?.symbol
        )}
      </Text>
      <Heading level={3}>Deposit Fund</Heading>
      <TextInput
        reverse={true}
        icon={<p>USDC</p>}
        value={
          amountToDeposit
            ? parseFloat(
                formatUnits(amountToDeposit || 0, baseTokenMetadata?.decimals)
              )
            : undefined
        }
        onChange={(event) => {
          const val = event.target.value;
          if (!Number.isNaN(val)) {
            setAmountToDeposit(parseUnits(val, baseTokenMetadata?.decimals));
          }
        }}
      />
      <RangeInput
        value={parseFloat(
          formatUnits(amountToDeposit || 0, baseTokenMetadata?.decimals)
        )}
        min={0}
        max={parseFloat(formatUnits(balance || 0, baseTokenMetadata?.decimals))}
        onChange={(event) => {
          setAmountToDeposit(
            parseUnits(event.target.value, baseTokenMetadata?.decimals)
          );
          setSignature(undefined);
        }}
      />

      <br />
      <Button
        fill="horizontal"
        disabled={fundTxState === TxState.PENDING}
        label={
          fundTxState === TxState.PENDING
            ? "Waiting tx is confirmed..."
            : !signature
            ? "Sign(Step 1/2)"
            : "Execute(Step 2/2)"
        }
        onClick={async () => {
          if (!metadata?.baseToken || !amountToDeposit) return;

          if (!signature) {
            if (!wcMessage("Go to your wallet and sign a message")) return;
            signPermitData(
              amountToDeposit,
              (await library?.getBlock("latest"))?.timestamp || 0
            )
              .catch((error) => console.error(error))
              .then((sig) => {
                if (sig) {
                  if (
                    !wcMessage(
                      "Completed to sign the message. Please go to your wallet again and execute the transaction"
                    )
                  )
                    return;
                  setSignature(sig);
                  fundWithPermit(amountToDeposit, sig);
                }
              });
          } else {
            if (!wcMessage("Go to your wallet and execute the transaction"))
              return;
            fundWithPermit(amountToDeposit, signature);
          }
        }}
      />
      <Heading level={3}>Withdraw Fund</Heading>
      <TextInput
        reverse={true}
        icon={<p>USDC</p>}
        value={
          amountToWithdraw
            ? parseFloat(
                formatUnits(amountToWithdraw || 0, baseTokenMetadata?.decimals)
              )
            : undefined
        }
        onChange={(event) => {
          const val = event.target.value;
          if (!Number.isNaN(val)) {
            setAmountToWithdraw(parseUnits(val, baseTokenMetadata?.decimals));
          }
        }}
      />
      <RangeInput
        value={parseFloat(
          formatUnits(amountToWithdraw || 0, baseTokenMetadata?.decimals)
        )}
        min={0}
        max={parseFloat(formatUnits(shares || 0, baseTokenMetadata?.decimals))}
        onChange={(event) => {
          setAmountToWithdraw(
            parseUnits(event.target.value, baseTokenMetadata?.decimals)
          );
          setSignature(undefined);
        }}
      />
      <br />
      <Button
        fill="horizontal"
        disabled={withdrawTxState === TxState.PENDING}
        label={
          withdrawTxState === TxState.PENDING
            ? "Waiting tx is confirmed..."
            : "Withdraw"
        }
        onClick={async () => {
          if (!metadata?.baseToken || !amountToWithdraw) return;
          if (!wcMessage("Go to your wallet and execute the transaction"))
            return;
          withdraw(amountToWithdraw);
        }}
      />
      <Heading level={3}>Release tokens</Heading>
      {tokens.length === 0 ? (
        <Text size={"large"}>No tokens to release yet</Text>
      ) : (
        tokens.map((address) => {
          return (
            <TokenRelease fundAddress={fund.address} tokenAddress={address} />
          );
        })
      )}
      {admin !== null && admin !== undefined && (
        <>
          <hr />
          <hr />
          <Text size={"large"}>
            <Anchor
              // href={`https://etherscan.io/address/${address}`}
              // target={"_blank"}
              onClick={() => navigate(`/fund/${fund.address}/admin`)}
            >
              Go to admin menu
            </Anchor>
          </Text>
        </>
      )}
    </Box>
  );

  return <>{allowance?.gt(0) ? details() : needPassword()}</>;
}

export default function FundDetails() {
  const { chainId } = useEthers();
  const funds = chainId ? fundList[chainId] : [];
  let { address } = useParams<"address">();
  const fund = funds.find(
    (f) => !!address && f.address === getAddress(address)
  );
  if (!fund) {
    return (
      <Box flex align="center" justify="center">
        <Heading level={3}>Not a valid fund address</Heading>
        <Text size={"large"}>
          <Anchor
            href={`https://etherscan.io/address/${address}`}
            target={"_blank"}
          >
            {address}
          </Anchor>
        </Text>
      </Box>
    );
  }

  return <Details fund={fund} />;
}
