import React, { useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Heading,
  RangeInput,
  TextInput,
  Text,
} from "grommet";
import { BigNumber } from "ethers";
import { useTransfer } from "../../hooks/private-equity/admin/useTransfer";
import { formatUnits, isAddress, parseUnits } from "ethers/lib/utils";
import { usePrivateEquityMetaData } from "../../hooks/private-equity/usePrivateEquityMetadata";
import { useERC20Metadata } from "../../hooks/erc20/useERC20Metadata";
import { useERC20Balance } from "../../hooks/erc20/useERC20Balance";
import { formatCurrency } from "../../utils/utils";
import { TxState } from "../../constants/tx";

export function Transfer({ fundAddress }: { fundAddress: string }) {
  const metadata = usePrivateEquityMetaData(fundAddress);
  const baseTokenMetadata = useERC20Metadata(metadata?.baseToken);
  const usdcBalance = useERC20Balance(metadata?.baseToken, fundAddress);
  const [amount, setAmount] = useState<BigNumber>();
  const [to, setTo] = useState<string>();
  const { txState, transfer } = useTransfer(fundAddress);
  return (
    <Box width={"medium"}>
      <Heading level={3}>Transfer Fund</Heading>
      <TextInput
        reverse={true}
        icon={<p>USDC</p>}
        value={
          amount
            ? parseFloat(formatUnits(amount || 0, baseTokenMetadata?.decimals))
            : undefined
        }
        onChange={(event) => {
          const val = event.target.value;
          if (!Number.isNaN(val)) {
            setAmount(parseUnits(val, baseTokenMetadata?.decimals));
          }
        }}
      />
      <RangeInput
        value={parseFloat(
          formatUnits(amount || 0, baseTokenMetadata?.decimals)
        )}
        min={0}
        max={parseFloat(
          formatUnits(usdcBalance || 0, baseTokenMetadata?.decimals)
        )}
        step={0.1}
        onChange={(event) => {
          console.log("set value");
          setAmount(
            parseUnits(event.target.value, baseTokenMetadata?.decimals)
          );
        }}
      />
      <br />
      <TextInput
        placeholder={`Ethereum address to send ${baseTokenMetadata?.symbol}`}
        value={to}
        onChange={(event) => {
          setTo(event.target.value);
        }}
      />
      <br />
      {isAddress(to || "") && (
        <Anchor href={`https://etherscan.io/address/${to}`} target={"_blank"}>
          Recipient: {to?.slice(0, 10)}...{to?.slice(-4)}
        </Anchor>
      )}
      <br />
      <Button
        disabled={txState === TxState.PENDING}
        label={
          txState === TxState.PENDING
            ? "Waiting tx is confirmed..."
            : "Transfer"
        }
        onClick={async () => {
          if (!metadata?.baseToken || !amount || !to) return;
          if (isAddress(to)) {
            transfer(to, amount);
          } else {
            window.confirm(
              `Invalid address to send ${baseTokenMetadata?.symbol}`
            );
          }
        }}
      />
    </Box>
  );
}
