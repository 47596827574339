import React, { useEffect, useState, useCallback } from "react";
import { useEthers } from "@usedapp/core";
import { usePrivateEquity } from "./usePrivateEquity";

export interface TokenDetails {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

export interface PrivateEquityMetadata {
  allowanceRoot: string;
  baseToken: string;
  feeTo: string;
  closed: boolean;
}

const cache: { [fundAddress: string]: PrivateEquityMetadata } = {};

export const usePrivateEquityMetaData = (
  fundAddress: string
): PrivateEquityMetadata | undefined => {
  // metadata
  const { library, chainId } = useEthers();
  const getCachedData = () => {
    if (!fundAddress) return undefined;
    const hasMemory = Object.prototype.hasOwnProperty.call(cache, fundAddress);
    if (hasMemory) {
      return cache[fundAddress];
    } else {
      return undefined;
    }
  };
  const [metadata, setMetadata] = useState<PrivateEquityMetadata | undefined>(
    getCachedData
  );
  const privateEquity = usePrivateEquity(fundAddress);

  const fetchFundMetadata = useCallback(async () => {
    if (!privateEquity) return;
    const [_allowanceRoot, _baseToken, _feeTo, _closed] = await Promise.all([
      privateEquity.allowanceRoot(),
      privateEquity.baseToken(),
      privateEquity.feeTo(),
      privateEquity.closed(),
    ]);
    const _metadata = {
      allowanceRoot: _allowanceRoot,
      feeTo: _feeTo,
      baseToken: _baseToken,
      closed: _closed,
    };
    cache[fundAddress] = _metadata;
    setMetadata(_metadata);
  }, [library, chainId, fundAddress]);

  useEffect(() => {
    fetchFundMetadata();
  }, [library, chainId, fundAddress]);
  return metadata;
};
