import React, { useCallback } from "react";
import { useEthers } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";

export const useWalletConnect = () => {
  const { library, activate } = useEthers();
  const activateWalletConnect = useCallback(async () => {
    const provider = new WalletConnectProvider({
      rpc: {
        1: "https://eth-mainnet.alchemyapi.io/v2/2Ll6NW_xQtnGlVoNRIvxzdU53ZwDc9Jg",
        5: "https://eth-goerli.alchemyapi.io/v2/BWCAN5J9-PqstQpPt8UswS-a-7HxvEOa",
      },
      qrcode: true,
    });
    try {
      const _wc = await provider.enable();
      console.log("enabled", _wc);
      await activate(provider);
    } catch (e) {
      console.log("Couldn't connect to the walletconnect connector");
      console.error(e);
    }
  }, []);
  const wcMessage = useCallback(
    (msg: string) => {
      const isWalletConnectProvider = library
        ? (library as any).provider instanceof WalletConnectProvider
        : false;
      if (isWalletConnectProvider) {
        return window.confirm(msg);
      } else {
        return true;
      }
    },
    [library]
  );
  return { activateWalletConnect, wcMessage };
};
