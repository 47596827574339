import React from "react";
import { Anchor, Box, Heading, Button, Text } from "grommet";
import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useReleaseToken } from "../hooks/private-equity/useReleaseToken";
import { TxState } from "../constants/tx";
import { useERC20Metadata } from "../hooks/erc20/useERC20Metadata";
import { useTotalReleasedETH } from "../hooks/private-equity/useTotalReleasedETH";
import { useTotalReleasedToken } from "../hooks/private-equity/useTotalReleasedToken";
import { useERC20Balance } from "../hooks/erc20/useERC20Balance";
import { useTotalShares } from "../hooks/private-equity/useTotalShares";
import { useShares } from "../hooks/private-equity/useShares";
import { useReleasedToken } from "../hooks/private-equity/useReleasedToken";
import { usePrivateEquityMetaData } from "../hooks/private-equity/usePrivateEquityMetadata";
import { useEthers } from "@usedapp/core";
import { isWalletConnect } from "../utils/utils";
import { useWalletConnect } from "../hooks/provider/useWalletConnect";

export default function TokenRelease({
  fundAddress,
  tokenAddress,
}: {
  fundAddress: string;
  tokenAddress: string;
}) {
  const { txState, releaseToken } = useReleaseToken(fundAddress, tokenAddress);
  const { wcMessage } = useWalletConnect();
  const fundMetadata = usePrivateEquityMetaData(fundAddress);
  const totalShares = useTotalShares(fundAddress);
  const myShares = useShares(fundAddress);
  const totalReleased = useTotalReleasedToken(fundAddress, tokenAddress);
  const releasedToken = useReleasedToken(fundAddress, tokenAddress);
  const balance = useERC20Balance(tokenAddress, fundAddress);
  const metadata = useERC20Metadata(tokenAddress);
  const totalReceived = totalReleased?.add(balance || 0);
  const mine = totalShares
    ? totalReceived?.mul(myShares || 0).div(totalShares)
    : undefined;
  const format = (val?: BigNumber, digits?: number) => {
    const parsed = parseFloat(
      formatUnits(val || 0, metadata?.decimals || 6)
    ).toFixed(digits || 3);
    return parsed.toString();
  };
  return (
    <Box>
      <Anchor
        href={`https://etherscan.io/address/${tokenAddress}`}
        target={"_blank"}
      >
        {metadata?.symbol}
      </Anchor>
      <Text>Withdrawable: {format(mine?.sub(releasedToken || 0))}</Text>
      <Text>Released: {format(releasedToken)}</Text>
      <br />
      <Button
        fill="horizontal"
        disabled={txState === TxState.PENDING || !fundMetadata?.closed}
        label={
          !fundMetadata?.closed
            ? "Fund is not closed yet"
            : txState === TxState.PENDING
            ? "Release token tx is confirmed..."
            : `Release ${metadata?.symbol}`
        }
        onClick={async () => {
          if (!wcMessage("Go to your wallet and execute the transaction"))
            return;
          releaseToken();
        }}
      />
    </Box>
  );
}
