import React, { useCallback, useState } from "react";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";
import { TxState } from "../../constants/tx";

export const useReleaseToken = (
  fundAddress: string,
  tokenAddress: string,
  holder?: string
) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [txState, setTxState] = useState<TxState>(TxState.BUILDING);
  const { library, account } = useEthers();

  const releaseToken = useCallback(async () => {
    if (!privateEquity || !account) return;
    if (!library) return;
    const signer = library.getSigner(account);
    privateEquity
      .connect(signer)
      .releaseToken(tokenAddress, holder || account)
      .then((tx) => {
        setTxState(TxState.PENDING);
        tx.wait()
          .then(() => setTxState(TxState.CONFIRMED))
          .catch(() => setTxState(TxState.FAILED));
      })
      .catch(() => setTxState(TxState.CANCELLED));
  }, [privateEquity, account, library, tokenAddress, holder]);
  return { txState, releaseToken };
};
