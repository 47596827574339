import React, { useCallback, useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import encryptedData from "@f3v/contracts/encrypted/data.json";
import { utils } from "@f3v/contracts";
import CryptoJS from "crypto-js";

export type MerkleTreeData = { [address: string]: number };
const cache: { [key: string]: MerkleTreeData } = {};

export const useMerkleTreeData = (fundAddress: string) => {
  const { chainId } = useEthers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCachedData = () => {
    if (!chainId) return undefined;
    const key = utils.getKey(chainId);
    const hasMemory = Object.prototype.hasOwnProperty.call(cache, key);
    if (hasMemory) {
      return cache[key];
    } else {
      return undefined;
    }
  };

  const [merkleTreeData, setMerkleTreeData] = useState<
    MerkleTreeData | undefined
  >(getCachedData);

  const key = `${chainId}:${fundAddress}`;

  const decryptData = useCallback(
    async (password: string) => {
      const data = (encryptedData as any)[key];
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data, password);
        const _merkleTreeData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        cache[key] = _merkleTreeData;
        setMerkleTreeData(_merkleTreeData);
      }
    },
    [key]
  );

  useEffect(() => {
    setMerkleTreeData(getCachedData());
  }, [getCachedData]);
  return { merkleTreeData, decryptData };
};
