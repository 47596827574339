import React, { useCallback } from "react";
import { BigNumber } from "ethers";
import { splitSignature } from "@ethersproject/bytes";
import {
  USDC_GOERLI,
  USDC_MAINNET,
  USDC_RINKEBY,
} from "../../constants/tokens";
import { useEthers } from "@usedapp/core";
import { useERC20Permit } from "./useERC20Permit";

export enum PermitType {
  AMOUNT = 1,
  ALLOWED = 2,
}

// 20 minutes to submit after signing
const PERMIT_VALIDITY_BUFFER = 20 * 60;

export interface PermitInfo {
  type: PermitType;
  name: string;
  // version is optional, and if omitted, will not be included in the domain
  version?: string;
}

// todo: read this information from extensions on token lists or elsewhere (permit registry?)
const PERMITTABLE_TOKENS: {
  [chainId: number]: {
    [checksummedTokenAddress: string]: PermitInfo;
  };
} = {
  1: {
    [USDC_MAINNET]: { type: PermitType.AMOUNT, name: "USD Coin", version: "2" },
  },
  4: {
    [USDC_RINKEBY]: { type: PermitType.AMOUNT, name: "USD Coin", version: "2" },
  },
  5: {
    [USDC_GOERLI]: { type: PermitType.AMOUNT, name: "USD Coin", version: "2" },
  },
};

interface BaseSignatureData {
  v: number;
  r: string;
  s: string;
  deadline: number;
  nonce: number;
  owner: string;
  spender: string;
  chainId: number;
  tokenAddress: string;
  permitType: PermitType;
}

interface StandardSignatureData extends BaseSignatureData {
  amount: string;
}

interface AllowedSignatureData extends BaseSignatureData {
  allowed: true;
}

export type SignatureData = StandardSignatureData | AllowedSignatureData;

const EIP712_DOMAIN_TYPE = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
];

const EIP712_DOMAIN_TYPE_NO_VERSION = [
  { name: "name", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
];

const EIP2612_TYPE = [
  { name: "owner", type: "address" },
  { name: "spender", type: "address" },
  { name: "value", type: "uint256" },
  { name: "nonce", type: "uint256" },
  { name: "deadline", type: "uint256" },
];

const PERMIT_ALLOWED_TYPE = [
  { name: "holder", type: "address" },
  { name: "spender", type: "address" },
  { name: "nonce", type: "uint256" },
  { name: "expiry", type: "uint256" },
  { name: "allowed", type: "bool" },
];

export function usePermitSignature(
  spender: string,
  tokenAddress?: string,
  overridePermitInfo?: PermitInfo | undefined | null
): {
  signPermitData: (
    amount: BigNumber,
    transactionDeadline: number
  ) => Promise<SignatureData | undefined>;
} {
  const { account, chainId, library } = useEthers();
  const { nonce } = useERC20Permit(tokenAddress);

  const permitInfo =
    overridePermitInfo ??
    (chainId && tokenAddress
      ? PERMITTABLE_TOKENS[chainId]?.[tokenAddress]
      : undefined);

  const signPermitData = useCallback(
    async (amount: BigNumber, transactionDeadline: number) => {
      return new Promise<SignatureData | undefined>(async (res, rej) => {
        if (
          !library ||
          !permitInfo ||
          !transactionDeadline ||
          !nonce ||
          !spender ||
          !chainId ||
          !tokenAddress ||
          !account
        ) {
          res(undefined);
          return;
        }
        const allowed = permitInfo.type === PermitType.ALLOWED;
        const signatureDeadline = transactionDeadline + PERMIT_VALIDITY_BUFFER;
        const value = amount.toString();

        const message = allowed
          ? {
              holder: account,
              spender,
              allowed,
              nonce: nonce.toNumber(),
              expiry: signatureDeadline,
            }
          : {
              owner: account,
              spender,
              value,
              nonce: nonce.toNumber(),
              deadline: signatureDeadline,
            };
        const domain = permitInfo.version
          ? {
              name: permitInfo.name,
              version: permitInfo.version,
              verifyingContract: tokenAddress,
              chainId,
            }
          : {
              name: permitInfo.name,
              verifyingContract: tokenAddress,
              chainId,
            };
        const data = JSON.stringify({
          types: {
            EIP712Domain: permitInfo.version
              ? EIP712_DOMAIN_TYPE
              : EIP712_DOMAIN_TYPE_NO_VERSION,
            Permit: allowed ? PERMIT_ALLOWED_TYPE : EIP2612_TYPE,
          },
          domain,
          primaryType: "Permit",
          message,
        });

        return library
          .send("eth_signTypedData_v4", [account, data])
          .then(splitSignature)
          .then((signature) => {
            const sig: SignatureData = {
              v: signature.v,
              r: signature.r,
              s: signature.s,
              deadline: signatureDeadline,
              ...(allowed ? { allowed } : { amount: value }),
              nonce: nonce.toNumber(),
              chainId,
              owner: account,
              spender,
              tokenAddress,
              permitType: permitInfo.type,
            };
            res(sig);
          })
          .catch(rej);
      });
    },
    [
      library,
      account,
      chainId,
      tokenAddress,
      permitInfo,
      overridePermitInfo,
      nonce,
    ]
  );
  return { signPermitData };
}
