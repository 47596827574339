import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { useEthers } from "@usedapp/core";

export const useETHBalance = () => {
  const [balance, setBalance] = useState<BigNumber>();
  const { account, library, chainId } = useEthers();

  const fetchBalance = useCallback(async () => {
    if (!account || !library) return;
    const _balance = await library.getBalance(account);
    setBalance(_balance);
  }, [library, chainId, account]);

  useEffect(() => {
    fetchBalance();
    library?.on("block", fetchBalance);
    return () => {
      library?.off("block", fetchBalance);
    };
  }, [library, chainId, account]);

  return balance;
};
