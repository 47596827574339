import React from "react";
import { Box, Heading } from "grommet";
import { useEthers } from "@usedapp/core";
import FundPreview from "../components/FundPreview";
import { fundList } from "../constants/fund";

export default function Explore() {
  const { chainId } = useEthers();
  const funds = chainId ? fundList[chainId] : [];
  return (
    <Box flex align="center" justify="center">
      <Heading level={3}>Explore funds</Heading>
      <Box
        direction="row"
        justify="center"
        align="center"
        pad="medium"
        gap="medium"
      >
        {funds.map((f) => (
          <FundPreview name={f.name} fundAddress={f.address} key={f.address} />
        ))}
      </Box>
    </Box>
  );
}
