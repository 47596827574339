import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PopulatedTransaction, Signer } from "ethers";
import Safe from "@gnosis.pm/safe-core-sdk";
import { ChainId, useEthers } from "@usedapp/core";
import { SafeTransactionDataPartial } from "@gnosis.pm/safe-core-sdk-types";
import SafeServiceClient from "@gnosis.pm/safe-service-client";
import { usePrivateEquity } from "../usePrivateEquity";
import { useGnosisSafe } from "./useGnosisSafe";

const cache: {
  owner?: string;
} = {};

const getCachedOwner = () => {
  return cache.owner;
};

const getGnosisAPI = (chainId?: number) => {
  return chainId === 1
    ? `https://safe-transaction.gnosis.io/`
    : chainId === 4
    ? `https://safe-transaction.rinkeby.gnosis.io/`
    : chainId === 5
    ? `https://safe-transaction.goerli.gnosis.io/`
    : undefined;
};

/**
 *
 * @param fundAddress
 * @returns {
 *  admin: returns
 *      safe when the admin is a multisig contract.
 *      signer when the admin is an EOA.
 *      undefined when it's fetching the data.
 *      null when the connected account is not an admin.
 * }
 */
export const useAdmin = (fundAddress: string) => {
  const { library, account, chainId } = useEthers();
  const [owner, setOwner] = useState<string | undefined>(getCachedOwner);
  const { safe } = useGnosisSafe(owner);

  const [eoaAdmin, setEOAAdmin] = useState<Signer | null | undefined>();

  const [admin, setAdmin] = useState<Safe | Signer | null | undefined>();

  const privateEquity = usePrivateEquity(fundAddress);

  const safeService = useMemo(() => {
    const api = getGnosisAPI(chainId);
    if (api) {
      const client = new SafeServiceClient(api);
      return client;
    } else {
      return undefined;
    }
  }, [chainId]);

  const fetchOwner = useCallback(async () => {
    if (!privateEquity) return;
    const _owner = await privateEquity.owner();
    cache.owner = _owner;
    setOwner(_owner);
    if (!!account) {
      if (account === _owner) {
        setEOAAdmin(library?.getSigner(_owner));
      } else {
        setEOAAdmin(null);
      }
    }
  }, [privateEquity, library, account]);

  const sendTx = useCallback(
    async (popTx: PopulatedTransaction, safeTxGas?: number) => {
      if (!account) return;
      const _admin = safe || eoaAdmin;
      if (_admin instanceof Signer) {
        const signedTx = _admin.signTransaction(popTx);
        const transaction = await library?.sendTransaction(signedTx);
        return {
          transaction,
        };
      } else if (_admin instanceof Safe) {
        const { to, data, value } = popTx;
        if (!to || !data) {
          throw Error("No target data.");
        }
        const partialTx: SafeTransactionDataPartial = {
          to,
          data,
          value: value?.toString() || "0",
          safeTxGas,
        };
        const safeTransaction = await _admin.createTransaction(partialTx);
        const safeTxHash = await _admin.getTransactionHash(safeTransaction);
        await _admin.signTransaction(safeTransaction);
        await safeService?.proposeTransaction({
          safeAddress: _admin.getAddress(),
          senderAddress: account,
          safeTransaction: safeTransaction,
          safeTxHash,
        });
        console.log("admin", _admin.getAddress());
        return {
          safeTransaction,
        };
      }
    },
    [safe, eoaAdmin, library, account, ChainId]
  );

  useEffect(() => {
    fetchOwner();
  }, [fetchOwner]);

  useEffect(() => {
    if (safe === null && eoaAdmin === null) {
      setAdmin(null);
    } else if (safe instanceof Safe) {
      setAdmin(safe);
    } else if (eoaAdmin instanceof Signer) {
      setAdmin(eoaAdmin);
    } else {
      setAdmin(undefined);
    }
  }, [safe, eoaAdmin]);

  return {
    admin,
    address: owner,
    sendTx,
  };
};
