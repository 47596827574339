import React, { useCallback, useEffect, useState } from "react";
import { getAddress } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import Safe from "@gnosis.pm/safe-core-sdk";
import EthersAdapter from "@gnosis.pm/safe-ethers-lib";
import { SafeTransactionDataPartial } from "@gnosis.pm/safe-core-sdk-types";
import { ethers, PopulatedTransaction } from "ethers";

const cache: {
  owners?: string[];
  isMultisigOwner?: boolean;
} = {};

export const useGnosisSafe = (safeAddress?: string) => {
  const { account, library } = useEthers();

  const getCachedMultisigOwners = () => {
    if (!safeAddress) return undefined;
    return cache.owners;
  };

  const getCachedIsMultisigOwner = () => {
    if (!safeAddress) return undefined;
    return cache.isMultisigOwner;
  };

  const [owners, setOwners] = useState<string[] | undefined>(
    getCachedMultisigOwners
  );
  const [isMultisigOwner, setIsMultisigOwner] = useState<boolean | undefined>(
    getCachedIsMultisigOwner
  );

  const [safe, setSafe] = useState<Safe | null | undefined>();

  useEffect(() => {
    if (library && safeAddress) {
      const signer = library?.getSigner();
      const ethAdapter = new EthersAdapter({ ethers, signer });
      Safe.create({
        ethAdapter,
        safeAddress,
      })
        .then(setSafe)
        .catch((reason) => {
          cache.isMultisigOwner = false;
          cache.owners = undefined;
          setIsMultisigOwner(false);
          setOwners(undefined);
          setSafe(null);
          console.log(`Is not a multisig: ${reason}`);
        });
    }
  }, [library, safeAddress]);

  const fetchOwners = useCallback(async () => {
    if (safe) {
      const _owners = await safe.getOwners();
      const _isMultisigOwner =
        !!account && _owners.includes(getAddress(account));
      cache.owners = _owners;
      cache.isMultisigOwner = _isMultisigOwner;
      setOwners(_owners);
      setIsMultisigOwner(_isMultisigOwner);
    }
  }, [account, safe]);

  useEffect(() => {
    fetchOwners();
  }, [fetchOwners]);

  return {
    safe,
    owners,
    isMultisigOwner,
  };
};
