import React, { useCallback, useEffect, useState } from "react";
import { usePrivateEquity } from "./usePrivateEquity";
import { useEthers } from "@usedapp/core";

export const useAllowanceRoot = (fundAddress: string) => {
  const privateEquity = usePrivateEquity(fundAddress);
  const [allowanceRoot, setAllowanceRoot] = useState<string>();
  const { library, chainId } = useEthers();

  const fetchAllowanceRoot = useCallback(async () => {
    if (!privateEquity) return;
    const _allowanceRoot = await privateEquity.allowanceRoot();
    setAllowanceRoot(_allowanceRoot);
  }, [privateEquity]);

  useEffect(() => {
    fetchAllowanceRoot();
  }, [fundAddress, library, chainId, fetchAllowanceRoot]);

  return allowanceRoot;
};
